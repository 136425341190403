<template>
  <LandingLayout>
    <template #header>
      <div
        class="tw-w-full tw-overflow-hidden tw-flex tw-justify-center tw-z-10"
        id="hero"
      >
        <Navbar />
      </div>
    </template>

    <div
      class="
        tw-transform tw--translate-y-72 tw--mb-72
        lg:tw--translate-y-108 lg:tw--mb-108
      "
    >
      <div class="tw-flex tw-justify-center tw-items-center tw-mb-16">
        <div class="tw-w-11/12 md:tw-w-5/6 lg:tw-w-3/4">
          <h1
            class="
              tw-text-white tw-text-5xl
              lg:tw-text-7xl
              tw-font-black tw-mb-4
            "
          >
            Nggak repot
          </h1>
          <h1 class="tw-text-white tw-text-5xl lg:tw-text-7xl tw-font-black">
            Buat jadi keren
          </h1>
        </div>
      </div>
      <div class="tw-w-full tw-flex tw-justify-center" id="about">
        <div
          class="
            tw-shadow-lg tw-flex tw-w-11/12
            md:tw-w-5/6
            lg:tw-w-3/4
            tw-pl-8 tw-pr-8
            md:tw-pr-10
            lg:tw-pr-0
            md:tw-pl-10
            lg:tw-pl-16
            tw-justify-between tw-bg-white tw-rounded-xl tw-overflow-hidden
          "
        >
          <div
            class="
              tw-flex-1 tw-py-8
              md:tw-py-10
              lg:tw-py-16
              tw-w-full
              md:tw-w-3/4
            "
          >
            <h1
              class="
                tw-text-4xl
                md:tw-text-5xl
                tw-mb-4 tw-font-extrabold tw-text-blue-primary
              "
            >
              Tentang Kami
            </h1>
            <div class="tw-text-xl tw-mb-4">
              Salonial adalah perusahaan teknologi yang menyediakan solusi bagi
              masyarakat untuk menemukan, menentukan serta memesan tempat
              terbaik dalam layanan perawatan rambut, wajah, kulit dan tubuh
              baik untuk wanita maupun pria melalui platform digital.
            </div>
            <div class="tw-text-xl tw-mb-4">
              Salonial mendukung seluruh pengusaha di industri ini baik kecil,
              menengah atau sedang maupun bear untuk meningkatkan market share
              dan mempertemukan dengan konsumen yang sesuai harapan.
            </div>
            <div class="tw-text-xl tw-mb-4">
              Melalui inovasi terus-menerus, Salonial berkeinginan untuk berkembang
              dan membantu seluruh mat manusia di dunia agar lebih teratur
              melakukan perawatan tubuh secara efisien dan hasil yang sesuai
              harapan.
            </div>
            <div class="tw-text-xl">
              Sesuai dengan misinya bahwa "Ngga repot buat jadi keren", Salonial
              diharapkan dapat memberikan kontribusi yang baik bagi keindahan
              dan berguna bag kesehatan masyarakat.
            </div>
          </div>
          <div
            class="
              tw-bg-red-500
              tw-flex-1
              tw-bg-gradient-to-r
              tw-from-white
              tw-hidden
              lg:tw-block
            "
          >
            <img
              src="../../public/images/priti/tentang.png"
              class="tw-object-cover tw-w-full tw-h-full"
              alt="Tentang Salonial"
            />
          </div>
        </div>
      </div>

      <div class="tw-flex tw-justify-center tw-my-16" id="statistic">
        <div
          class="
            tw-grid tw-grid-cols-1
            md:tw-grid-cols-2
            lg:tw-grid-cols-3
            tw-gap-36 tw-p-8 tw-m-8
          "
        >
          <div
            class="
              tw-flex
              tw-flex-col
              tw-justify-center
              tw-items-center
              tw-p-8
              tw-w-56
              tw-h-56
            "
          >
            <img src="../../public/images/priti/download.png" alt="Download" />
            <p class="tw-font-extrabold tw-text-xl tw-text-blue-light tw-mt-4">
              1000+
            </p>
            <p class="stat-detail">App diunduh</p>
          </div>
          <div
            class="
              tw-flex
              tw-flex-col
              tw-justify-center
              tw-items-center
              tw-p-8
              tw-w-56
              tw-h-56
            "
          >
            <img src="../../public/images/priti/user.png" alt="User" />
            <p class="tw-font-extrabold tw-text-xl tw-text-blue-light tw-mt-4">
              1000+
            </p>
            <p class="stat-detail">Pengguna aktif</p>
          </div>
          <div
            class="
              tw-col-span-1
              md:tw-col-span-2
              lg:tw-col-span-1
              tw-flex tw-justify-center tw-items-center
            "
          >
            <div
              class="
                tw-flex
                md:tw-col-span-2
                lg:tw-col-span-1
                tw-flex-col
                tw-justify-center
                tw-items-center
                tw-p-8
                tw-w-56
                tw-h-56
              "
            >
              <img src="../../public/images/priti/rating.png" alt="Rating" />
              <p
                class="tw-font-extrabold tw-text-xl tw-text-blue-light tw-mt-4"
              >
                1000+
              </p>
              <p class="stat-detail">Rating di playstore</p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="
          tw-w-full
          tw-relative
          tw-flex
          tw-bg-blue-primary
          tw-justify-center
          tw-items-center
        "
        id="feature"
      >
        <div
          class="
            tw-flex tw-w-5/6 tw-flex-col
            lg:tw-flex-row
            tw-justify-between tw-space-x-16
          "
        >
          <div class="tw-flex-1 tw-py-16 lg:tw-py-56">
            <h2 class="tw-text-4xl tw-text-white tw-font-bold tw-mb-8">
              Fitur
            </h2>
            <div class="tw-text-white tw-text-2xl tw-mb-8">
              <p class="tw-mb-4">
                Kemudahan dalam reservasi tempat, waktu dan stylist terbaik
                serta produk perawatan yang sesuai menjadikan Salonial sebuah
                platform yang menjawab kebutuhan banyak orang.
              </p>
              <p>
                Salonial membantu manajemen Salon & Spa dalam manajemen waktu.
                Jadwal pelayanan konsumen, jadwal pekerja Salon & Spa lebih
                mudah, maksimal dan efisien diatur dan dimonitor.
              </p>
            </div>
          </div>
          <div class="tw-flex tw-justify-center tw-flex-1">
            <img
              class="tw-relative lg:tw-absolute tw-bottom-0"
              src="~@/assets/images/phone.png"
              alt="Fitur Salonial"
            />
          </div>
        </div>
      </div>

      <div
        class="
          tw-w-full tw-flex tw-justify-center tw-items-center tw-bg-gray-200
        "
        id="register"
      >
        <div
          class="
            tw-w-full
            md:tw-w-5/6
            lg:tw-w-3/4
            tw-bg-white tw-p-8
            md:tw-p-16
            lg:tw-p-24
            tw-m-4
            md:tw-m-16
            lg:tw-m-24
            tw-flex tw-flex-col tw-justify-center tw-items-center tw-rounded-xl
          "
        >
          <h2
            class="
              tw-font-extrabold tw-text-2xl
              md:tw-text-4xl
              tw-mb-8 tw-text-blue-primary
            "
          >
            Ingin salonmu ada di Salonial?
          </h2>
          <div class="tw-text-xl md:tw-text-2xl tw-mb-8">
            Kembangkan bisnismu bersama kami, perbanyak income salonmu bersama
            Salonial!
          </div>
          <router-link to="/register">
            <button
              class="
                tw-text-white tw-rounded
                hover:tw-bg-blue-light
                tw-text-lg
                md:tw-text-xl
                tw-font-bold tw-bg-blue-primary tw-p-5
              "
            >
              Gabung Sekarang
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </LandingLayout>
</template>

<script>
import LandingLayout from "@/layouts/LandingLayout";
import Navbar from "@/components/Landing/Navbar";

export default {
  components: { Navbar, LandingLayout },
  mounted() {
    window.location = 'https://salonial.com';
  }
};
</script>

<style scoped>
#hero {
  background-image: linear-gradient(
      to bottom,
      rgba(1, 1, 1, 0) 0%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("../../public/layout/images/priti/header-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 90vh;
}
</style>